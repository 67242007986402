export const projectImages = [
    '/images/web_icons/1.png',
    '/images/web_icons/2.png',
    '/images/web_icons/3.png',
    '/images/web_icons/4.png',
    '/images/web_icons/5.png',
]


const projectsData = [
    {
        title: 'Task Manager',
        image: '/images/projects/task-manager.png',
        description: 'The project was made to practice with react-router-dom and learn how to use the styled component',
        links: [
            {
                source: 'git',
                url: 'https://github.com/cecam/task-administrator'
            },
            {
                source: 'web',
                url: 'https://elegant-williams-943090.netlify.app/'
            },
        ]
    },
    {
        title: 'Real time chat',
        image: '/images/projects/rt-chat.png',
        description: 'The first project developed for this portfolio, I used the system to learn how the real time data bases works and was my first time using sass to style the system',
        links: [
            {
                source: 'git',
                url: 'https://github.com/cecam/realtime-chat'
            },
            {
                source: 'web',
                url: 'https://eloquent-lichterman-d4c097.netlify.app/'
            },
        ]
    },
    {
        title: 'Cabo Marqués',
        image: '/images/projects/cabo-marques.png',
        description: 'Site built for a client in Acapulco, I worked hand to hand with a design team',
        links: [
            {
                source: 'web',
                url: 'https://cabomarques.gmd.mx/'
            },
        ]
    },
]


export default projectsData 
import { CircleMenu, CircleMenuItem, TooltipPlacement } from "react-circular-menu";
import { FiUser, FiFolder, FiMessageCircle, FiFileText } from "react-icons/fi";

const Navbar = () => {
    return (
        <CircleMenu
            startAngle={70}
            rotationAngle={180}
            itemSize={1}
            radius={3}
            className="navbar"
            rotationAngleInclusive={false}
        >
            <CircleMenuItem 
                link="mailto:cardenascelso9@gmail.com"
                tooltip="Contact me!"
                tooltipPlacement={TooltipPlacement.Right}
            >
                <FiMessageCircle />
            </CircleMenuItem>
            <CircleMenuItem
                link="#porfolio" 
                tooltip="Portfolio"
                tooltipPlacement={TooltipPlacement.Right}
            >
                <FiFolder />
            </CircleMenuItem>
            <CircleMenuItem
                link="#experience" 
                tooltip="Experience"
                tooltipPlacement={TooltipPlacement.Right}
            >
                <FiFileText />
            </CircleMenuItem>            
            <CircleMenuItem
                link="#me"
                tooltip="Me"
                tooltipPlacement={TooltipPlacement.Right}
            >
                <FiUser />
            </CircleMenuItem>
        </CircleMenu>
    )
}

export default Navbar
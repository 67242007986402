import ExperienceBlock from "../components/ExperienceBlock"
import Skills from "../components/Skills"

import data from "../contentData/experience"

const Experience = () => {
    return (
        <section id="experience" className="Experience">
            <h2 className="Experience__title">My experience</h2>

            <div className="Experience__grid">
                {data.map((experience, i) => (
                    <ExperienceBlock key={i} {...experience} />
                ))}
            </div>

            <Skills />
        </section>
    )
}

export default Experience
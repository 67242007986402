

const ExperienceBlock = ({title, link, dates, job, responsabilities, responsabilitie}) => {
    return (
        <div className="Experience__card">
            <div className="Experience__card_header">
                {link ? 
                    <a href={link}>
                        <h3>{title}</h3>
                    </a>
                    :
                    <h3>{title}</h3>
                }
                 <p>{dates}</p>
            </div>
            <div className="Experience__card_subheader">
                {job}
            </div>

            <div className="Experience__card_content" >
                {responsabilities ? 
                    <ul>
                        {responsabilities.map((element, key) => (
                            <li key={key}>{element}</li>
                        ))}
                    </ul>
                    :
                    <p>{responsabilitie}</p>
                }
            </div>
        </div>
    )
}

export default ExperienceBlock
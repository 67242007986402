import { DiReact, DiJsBadge, DiGithubBadge } from "react-icons/di";
import { SiNextdotjs, SiTypescript, SiAmazonaws } from "react-icons/si";


const Skills = () => {
    const skillsArray = [
        {
            name: 'React JS',
            component: <DiReact />
        },
        {
            name: 'Next JS',
            component: <SiNextdotjs />
        },
        {
            name: 'AWS',
            component: <SiAmazonaws />
        },
        {
            name: 'ES 6+',
            component: <DiJsBadge />
        },
        {
            name: 'TypeScript',
            component: <SiTypescript />
        },
        {
            name: 'Git/Github',
            component: <DiGithubBadge />
        },
    ]

    return (
        <div className="Skills">
            <h3 className="Skills__subtitle">Skills</h3>

            <div className="Skills__grid">  
                {skillsArray.map(skill => (
                    <div key={skill.name} className="Skills__grid_element">
                        {skill.component}
                        <p>{skill.name}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Skills
import { useState, useEffect } from "react"
import { FiXCircle, FiEye, FiGithub } from "react-icons/fi";
import { useTransition, animated } from "react-spring";

import OutsideWrapper from "./OutsideWrapper";

import { projectImages } from "../contentData/projects";





const Modal = ({project}) => {
    const [ isOpen, setIsOpen ] = useState(false)
    const [ image, setImage ] = useState('')
    const transition = useTransition(isOpen, {
        config: { duration: 250 },
        from: { opacity: 0 } ,
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        
    })

    const getIcon = (source) => {
        switch (source) {
            case 'web':
                return <FiEye />
                
            case 'git':
                return <FiGithub />
            default:
                return null
        }
    }

    useEffect(() => {
        const index = Math.floor(Math.random() * (5 - 0)) + 0;
        console.log(index, projectImages[index]);
        setImage(projectImages[index])
    }, [])

    return (
        <OutsideWrapper setIsOpen={setIsOpen}>
            <div className="Modal__button" onClick={() => setIsOpen(true)}>
                <img src={image} alt="" />
                <h3>{project.title}</h3>
            </div>
            
            {transition((style, item) => 
                item && 
                    <animated.div style={style} className="Modal">
                        <div className="Modal__content">
                            <div className="Modal__header">
                                <h2>{project.title}</h2>
                                <button onClick={() => setIsOpen(false)} ><FiXCircle /></button>
                            </div>
                            <div className="Modal__body">
                                <img src={project.image} alt={project.name} />
                                <p>{project.description}</p>
                            </div>
                            <div className="Modal__footer">
                                {project.links.map(link => (
                                    <a key={link.url} href={link.url} target="_blank" rel="noreferrer">
                                        <button>{getIcon(link.source)}</button>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </animated.div>
            )}
        </OutsideWrapper>
    )
}

export default Modal
import Modal from "../components/Modal"
import projectsData from '../contentData/projects.js'

const Portfolio = () => {
    return (
        <section id="porfolio" className="Portfolio">
            <h2 className="Portfolio__title">My projects</h2>

            <div className="Portfolio__grid">
                {projectsData.map((project, i) => (
                    <Modal project={project} key={i} />
                ))}
            </div>


        </section>
    )
}

export default Portfolio
import { FaLinkedinIn, FaTiktok, FaGithub } from "react-icons/fa";

const Me = () => {
    const socialMedia = [
        {
            url: 'https://www.linkedin.com/in/celso-cardenas-macias/',
            icon: <FaLinkedinIn />
        },
        {
            url: 'https://vm.tiktok.com/ZMLnsU3JD/',
            icon: <FaTiktok />
        },
        {
            url: 'https://github.com/cecam',
            icon: <FaGithub />
        },
    ]

    return (
        <section className="Me" id="me">
            <div className="Me__avatar">
                <img src="/images/avatar.png" alt="avatar" />
            </div>
            <div className="Me__description">
                <div className="Me__description_section">
                    <h1>Hey! My name is Celso.</h1>
                    <h2>I'm a frontend developer based in Mexico</h2>
                </div>
                <p>I have been working since 2019. I've always been somone who is attracted to new challenges. For that reason, I'm always trying to learn and grow up as a developer.</p>
                <p>I am a self-taught person, optimist and I drive myself very easily working by myself or as part of a team</p>
                <div className="Me__description_social">
                    {socialMedia.map(media => (
                        <a key={media.url} href={media.url} target="_blank" rel="noreferrer" >
                            {media.icon}
                        </a>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Me
import { useState } from 'react';

import './styles/index.scss'

import Navbar from './components/Navbar';
import { Me, Portfolio, Experience } from './sections'

function App() {

  return (
    <div>
      <Navbar />
      <Me />
      <Experience />
      <Portfolio />
    </div>
  );
}

export default App;

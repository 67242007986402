const experienceData = [
    {
        title: 'EasyTrack',
        link: 'https://easytrack.mx/',
        dates: 'jul. 2021 - current',
        job: 'Frontend Developer',
        responsabilities: [
            'Frontend Developer (React js, NextJS, React Query)',
            'Mobile Developer (Ionic 5 - React)',
            'Backend Developer (AWS - Api Gateway, Cognito, Lambda, Cloudfront)'
        ]
    },
    {
        title: 'Excite - Inbound Marketing',
        link: 'https://www.excite.com.mx/',
        dates: 'jan. 2021 - jul. 2021',
        job: 'Frontend Developer',
        responsabilities: [
            'Frontend Developer (React, Ant Design, React Native, Sass, Gatsby JS)',
            'SEO auditor and developer over Wordpress and Gatsby JS sites',
            'Lead some projects with designers and backend developers',
            'I was part of a team to develop complex projects',
        ]
    },
    {
        title: 'SchoolNotes',
        dates: 'jan. 2020 - dec. 2020',
        job: 'CEO - Frontend Developer',
        responsabilitie: 'My own startup; it went broke, but I got a lot of new knowledge and experience in lean startup, Scrum, and at the same time, I was able to improve my empathy leading a small team.'
    },
    {
        title: 'Excite - Inbound Marketing',
        link: 'https://www.excite.com.mx/',
        dates: 'nov. 2019 - jan. 2021',
        job: 'Intern',
        responsabilities: [
            'Frontend Developer (Vue, Vuetify, NuxtJS, React)',
            'Seo audithor and developer over Wordpress sites',
            'Worked hand to hand with a backend team to create an build complex projects'
        ]
    },
]

export default experienceData